import { createApp } from "vue";

import "@/assets/css/index.css";

import { MOUNT_ELEMENTS } from "@/constants/elements";

import LoaderUI from "@/components/UI/Loader/LoaderUI.vue";
import RecommendationsWithCatalog from "@/components/Analyses/RecommendationsWithCatalog/AsyncWrapper.vue";

import IngosLanding from "@/partnerships/Ingos/components/IngosLanding/AsyncWrapper.vue";

import PersonalCertificates from "@/components/Personal/Certificates/AsyncWrapper.vue";
import PersonalBillBonuses from "@/components/Personal/BillBonuses/AsyncWrapper.vue";

import CheckPoll from "@/components/Polls/Check/AsyncWrapper.vue";

import TatneftLanding from "@/partnerships/Tatneft/components/TatneftLanding/AsyncWrapper.vue";

createApp(LoaderUI).mount(`#${MOUNT_ELEMENTS.LOADER}`);
createApp(PersonalCertificates).mount(
  `#${MOUNT_ELEMENTS.PERSONAL_CERTIFICATES}`
);
createApp(IngosLanding).mount(`#${MOUNT_ELEMENTS.PARTNERSHIP_INGOS_LANDING}`);
createApp(RecommendationsWithCatalog).mount(
  `#${MOUNT_ELEMENTS.RECOMMENDATIONS_WITH_CATALOG}`
);
createApp(PersonalBillBonuses).mount(
  `#${MOUNT_ELEMENTS.PERSONAL_BILL_BONUSES}`
);
createApp(CheckPoll).mount(`#${MOUNT_ELEMENTS.CHECK_POLL}`);
createApp(TatneftLanding).mount(`#${MOUNT_ELEMENTS.TATNEFT_LANDING}`);
