<template>
  <Lab4uTransition :withAppear="true">
    <TatneftLanding />
  </Lab4uTransition>
</template>

<script setup lang="ts">
import Lab4uTransition from "@/components/UI/Transitions/Lab4uTransition.vue";
import { defineAsyncComponent } from "vue";

const TatneftLanding = defineAsyncComponent(
  () => import("./TatneftLanding.vue")
);
</script>
