export const HEADER_CLASS = "page-header2";

/**
 * Id's of mount elements (vue apps)
 */
export const MOUNT_ELEMENTS = {
  /**
   * main loader components
   *
   * @see `local/js/lab4u_frontend/src/components/UI/Loader`
   */
  LOADER: "loader",

  /**
   * personal certificates
   * @see `/personal/certificates/`
   * @see `local/js/lab4u_frontend/src/components/Personal/Certificates`
   */
  PERSONAL_CERTIFICATES: "Certificates",

  /**
   * partnership with Ingos
   * @see `/partnership/ingos/`
   * @see `local/js/lab4u_frontend/src/partnerships/Ingos/components/IngosLanding`
   */
  PARTNERSHIP_INGOS_LANDING: "PartnershipIngosLanding",

  /**
   * recommendations on main page
   * @see `local/js/lab4u_frontend/src/components/Analyses/RecommendationsWithCatalog`
   */
  RECOMMENDATIONS_WITH_CATALOG: "MainRecommendations",

  /**
   * user's bills
   * @see `/personal/bill/`
   * @see `local/js/lab4u_frontend/src/components/Personal/BillBonuses`
   */
  PERSONAL_BILL_BONUSES: "PersonalBillBonuses",

  /**
   * Main poll and integration with soglasie
   *
   * @see `/check-soglasie/`
   * @see `/checkup/`
   * @see `local/js/lab4u_frontend/src/components/Polls/Check`
   */
  CHECK_POLL: "CheckPoll",

  /**
   * Tatneft landing (partnership)
   *
   * @see `/partnership/tatneft/`
   * @see `local/js/lab4u_frontend/src/partnerships/Tatneft/components/TatneftLanding`
   */
  TATNEFT_LANDING: "PartnershipTatneftLanding",
};
